
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockContentCard",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
	},
	computed: {
		overlineText() {
			return this.settings?.overline
				? renderData(this.settings?.overline, this.dataSite)
				: null;
		},
		headlineText() {
			return this.settings?.headline
				? renderData(this.settings?.headline, this.dataSite)
				: null;
		},
		bodyText() {
			// Standardized name is 'bodyText', but some sites still have 'richText'
			const textValue =
				this.settings?.bodyText || this.settings?.richText;
			return textValue ? renderData(textValue, this.dataSite) : null;
		},
		disclosureText() {
			return this.settings?.disclosure
				? renderData(this.settings.disclosure, this.dataSite)
				: null;
		},
		mediaSrc() {
			const mediaSrc = this.settings?.media;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		mediaAlt() {
			const mediaSrc = this.settings?.media;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.alt
				: this.settings?.alt;
		},
		mediaMetadata() {
			const background = this.settings?.media;
			const mediaSrc =
				background && typeof background === "string"
					? this.settings?.mediaMetadata?.media
					: this.settings?.media;
			if (!mediaSrc) return { src: background };
			const { crop } = mediaSrc;
			return { src: crop ? crop : mediaSrc };
		},
		wrapText() {
			return this.settings?.variants?.wrapText || false;
		},
		mediaHeight() {
			return this.settings?.mediaHeight;
		},
		mediaWidth() {
			return this.settings?.mediaWidth || "auto";
		},
		hasButtons() {
			return this.hasPrimaryButton || this.hasSecondaryButton;
		},
		hasPrimaryButton() {
			return this.settings?.primaryButton || false;
		},
		hasSecondaryButton() {
			return this.settings?.secondaryButton || false;
		},
		secondaryButtonAsPrimary() {
			return this.settings?.variants?.["secondary-as-primary"] || false;
		},
		hasOnlyOneButton() {
			return (
				(this.hasPrimaryButton || this.hasSecondaryButton) &&
				!(this.hasPrimaryButton && this.hasSecondaryButton)
			);
		},
		hasSocialIcons() {
			return (
				this.blockFacebook ||
				this.blockInstagram ||
				this.blockLinkedin ||
				this.blockTwitter ||
				this.blockYoutube
			);
		},
		blockFacebook() {
			if (!this.settings?.["facebook-show"]) return "";
			return renderData(this.settings.facebook, this.dataSite);
		},
		blockLinkedin() {
			if (!this.settings?.["linkedin-show"]) return "";
			return renderData(this.settings.linkedin, this.dataSite);
		},
		blockInstagram() {
			if (!this.settings?.["instagram-show"]) return "";
			return renderData(this.settings.instagram, this.dataSite);
		},
		blockTwitter() {
			if (!this.settings?.["twitter-show"]) return "";
			return renderData(this.settings.twitter, this.dataSite);
		},
		blockYoutube() {
			return this.settings.youtube || null;
		},
		windowWidth() {
			return this.$vuetify.breakpoint.width;
		},
		imageLocation() {
			return this.settings.variants["align-image"];
		},
	},
	methods: {
		contentCardClick() {
			if (this.hasOnlyOneButton) {
				const url = this.hasPrimaryButton
					? this.settings.primaryUrl
					: this.settings.secondaryUrl;
				const target = this.hasPrimaryButton
					? this.settings.primaryBtnNewWindow
					: this.settings.secondaryBtnNewWindow;
				url.startsWith("/")
					? this.openInternal(url)
					: this.openExternal(url, target);
			}
		},
		openInternal(link) {
			this.$router.push(link);
		},
		openExternal(link, target) {
			window.open(link, target);
		},
	},
};
